import { partyColors } from '~/hooks/useVotes';
import './style.css';

export const TallyBar = ({ percentage }: { percentage: number }) => {
	const row = (
		/* 1% gap between dots */
		<div className="space-x-[1%] bar">
			{Array(33) /* 100 dots is too many, they're too small */
				.fill(null)
				.map((_, i) => {
					const isHardRated = i * 3 < percentage;
					const isNextHardRated = (i + 1) * 3 < percentage;

					const isTheLastOneBeforeTheOtherColors = isHardRated && !isNextHardRated;

					const isTheOnlyOne = isHardRated && !isNextHardRated && i === 0;

					const showLowOpacity =
						(percentage > 50 && !isHardRated) ||
						(percentage < 50 && isHardRated) ||
						percentage === -1;

					return (
						<div
							key={i}
							className={`w-[2%] aspect-square rounded-full inline-block transition-all duration-500`}
							style={{
								opacity: isTheLastOneBeforeTheOtherColors
									? isTheOnlyOne
										? 1
										: 0
									: showLowOpacity
										? 0.4
										: 1,
								backgroundColor:
									percentage === -1
										? 'white'
										: i * 3 < percentage
											? partyColors.hard_rated
											: partyColors.great_northern,
							}}
						/>
					);
				})}
		</div>
	);
	return (
		<div
			className="tally-bar leading-none space-y-[2px]"
			style={{ fontSize: 0 }}
			data-percentage={percentage}
		>
			{row} {row}
			{row} {row}
		</div>
	);
};
