import { regionLabels, useVotes } from '~/hooks/useVotes';
import { TallyBar } from './TallyBar';
import { useFocusedRegion } from '../RegionFocuser/useFocusedRegion';

export const VotesTally = () => {
	const { loading, votes, error } = useVotes();
	const [focusedRegion] = useFocusedRegion();

	return (
		<table className="w-full border-separate border-spacing-2 lg:border-spacing-8">
			<tbody>
				{[...regionLabels.entries()]
					.filter(([region]) => {
						if (!focusedRegion) {
							return true;
						}

						if (focusedRegion === 'all') {
							return true;
						}

						return region === focusedRegion;
					})
					.map(([region, label]) => {
						const total = votes[region]?.hard_rated + votes[region]?.great_northern;
						let split = votes[region]?.hard_rated / total;

						if (isNaN(split) || loading) {
							split = -1;
						} else {
							split = Math.round(split * 100);
						}

						return (
							<tr
								key={region}
								className={
									focusedRegion === 'all' && region !== 'all'
										? 'hidden md:table-row'
										: ''
								}
							>
								<td className="hidden md:table-cell text-left w-2/5">{label}</td>
								<td className="w-full md:w-3/5">
									<TallyBar percentage={split} />
								</td>
							</tr>
						);
					})}
			</tbody>
		</table>
	);
};
